import { backofficeApi } from "implementations";
import { GetServerSideProps } from "next";
import { Login } from "../../src/screens/Login";
import { isBlackFridayServerSideProps } from "../../src/utils/server-side";

export const getServerSideProps: GetServerSideProps = async () => {
  const blackFridayTheme = await isBlackFridayServerSideProps(backofficeApi);

  return {
    props: {
      blackFridayTheme,
    },
  };
};

export default Login;
