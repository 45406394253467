import { Main } from "components";
import { AuthContent } from "components/patterns/Header/components/AuthContent";
import { ReactElement, useCallback } from "react";
import { useAuthentication, useShallow } from "application/state-manager";
import { getAuthContent } from "components/patterns/Header/components/data";
import { StepName } from "typing";
import { NextPageWithLayout } from "../../../pages/_app";

const Login: NextPageWithLayout = () => {
  const { currentStep } = useAuthentication(
    useShallow((state) => ({
      currentStep: state.currentStep,
    }))
  );

  const renderAuthContent = useCallback((step: StepName) => {
    return getAuthContent("page", step);
  }, []);

  return (
    <AuthContent loginMode="page">{renderAuthContent(currentStep)}</AuthContent>
  );
};

Login.getLayout = (page: ReactElement) => {
  return <Main>{page}</Main>;
};

export { Login };
